<template>
  <q-page class="favouritesPage">
    <template v-if="isLoggedIn">
      <template v-if="loading && !isMobile">
        <div class="page-title">
          <div class="row items-center full-width">
            <div class="col-6 col-md-6 q-my-xs">
              <q-skeleton width="200px" height="20px" />
              <q-skeleton
                type="text"
                width="160px"
                height="20px"
                class="q-mt-sm"
              />
            </div>
            <div class="col-12 col-md-2 offset-md-4 col-lg-1 offset-lg-5">
              <q-skeleton width="100%" height="20px" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="page-title"
          v-if="!isMobile"
          v-show="loading || totalCount > 0 || categories.length > 0"
        >
          <div class="page-title--left">
            <h1> Favorites ({{ totalCount }}) </h1>
            <q-breadcrumbs>
              <q-breadcrumbs-el label="Home" :to="{ name: 'Home' }" />
              <q-breadcrumbs-el label="Favorites" />
            </q-breadcrumbs>
          </div>
          <div class="page-title--right">
            <q-skeleton v-if="loadingCategory" width="100px" height="40px" />
            <div
              class="sorting flex items-center"
              v-else-if="categories && categories.length > 1"
            >
              <span>Category:</span>
              <q-select
                dense
                borderless
                :value="favoriteFilters.category"
                :options="categories"
                option-value="categoryID"
                :option-label="(item) => item ? $options.filters.titleCase(item.categoryName) : ''"
                emit-value
                map-options
                transition-show="jump-up"
                transition-hide="jump-up"
                @input="
                  (val) => {
                    onChangeCategory(val)
                    refreshListing(val)
                  }
                "
              />
            </div>
            <div
              class="showitems flex items-center"
              v-if="categories.length > 1 && sortOptions"
            >
              <span>Sort:</span>
              <q-select
                dense
                borderless
                :value="favoriteFilters.sortBy"
                :options="sortOptions"
                option-value="value"
                option-label="label"
                emit-value
                map-options
                transition-show="jump-up"
                transition-hide="jump-up"
                @input="
                  (val) => {
                    sortByModified(val)
                    refreshListing(val)
                  }
                "
              />
            </div>
            <ProductLayoutIcons
              @changeView="changeView"
              ref="productLayoutIcons"
              :isListView="!isMobile ? favoriteFilters.isListView : false"
            />
          </div>
        </div>
        <template v-else-if="isMobile">
          <FilterButtons
            :filterBtnClick="
              () => {
                this.showCategoryDialog = true
              }
            "
            :sortBtnClick="
              () => {
                this.showSortByDialog = true
              }
            "
            filterBtnName="Category"
          />

          <q-dialog
            v-if="categories && categories.length > 1"
            v-model="showCategoryDialog"
            position="bottom"
            maximized
          >
            <q-card class="mobileCardDialogue">
              <q-item-label class="mobileSortFilter">
                <h5 class="text-h5">Filter by</h5>
                <q-btn class="close" flat dense v-close-popup>
                  <q-icon class="close cursor-pointer" name="close" />
                </q-btn>
              </q-item-label>
              <q-scroll-area
                :thumb-style="scrollAreaThumbStyle"
                :bar-style="scrollAreaBarStyle"
                :visible="scrollAreaVisible"
                style="height: calc(100vh - 170px)"
              >
                <q-option-group
                  class="q-pa-md"
                  :options="categoriesOption"
                  type="radio"
                  :value="favoriteFilters.category"
                  @input="
                    (val) => {
                      onChangeCategory(val)
                      refreshListing(val)
                    }
                  "
                >
                  <template v-slot:label="category">
                    {{ $options.filters.titleCase(category.label) }}
                  </template>
                </q-option-group>
              </q-scroll-area>
            </q-card>
          </q-dialog>
          <q-dialog
            v-model="showSortByDialog"
            position="bottom"
            transition-hide="slide-down"
            transition-show="slide-up"
            maximized
          >
            <q-card class="mobileCardDialogue">
              <q-item-label class="mobileSortFilter">
                <h5 class="text-h5">Sort by</h5>
                <q-btn class="close" flat dense v-close-popup>
                  <q-icon class="close cursor-pointer" name="close" />
                </q-btn>
              </q-item-label>
              <q-option-group
                class="q-pa-md"
                :options="sortOptions"
                type="radio"
                :value="favoriteFilters.sortBy"
                @input="
                  (val) => {
                    sortByModified(val)
                    refreshListing(val)
                  }
                "
              />
            </q-card>
          </q-dialog>
        </template>
      </template>

      <div class="page-content favouriteItems">
        <q-infinite-scroll
          @load="fetchNext"
          :offset="1500"
          class="product-items"
          ref="infiniteScroll"
        >
          <ProductCard
            v-for="(product, index) in favorites"
            :product="product"
            :showClose="true"
            :key="product.productID + '-' + index"
            @removeItemFromFavorites="scrollPosition"
            :class="{
              'list-view': !isMobile ? favoriteFilters.isListView : false,
            }"
            :isListView="!isMobile ? favoriteFilters.isListView : false"
          />
          <template v-slot:loading>
            <ProductSkeleton
              v-show="loading"
              :isListView="!isMobile ? favoriteFilters.isListView : false"
            />
            <LoadMoreSpinner v-show="!loading" />
          </template>
        </q-infinite-scroll>
      </div>

      <EmptyComponent
        v-show="!loading && totalCount == 0"
        image="images/empty-wishlist.png"
        btnText="Start Shopping"
        to="/"
      >
        <p>You don't have any Favorites.</p>
      </EmptyComponent>
    </template>
    <EmptyComponent
      v-else
      image="images/empty-wishlist.png"
      btnText="Sign In"
      btnClass="q-pr-lg q-pl-lg q-mt-sm "
      :btnClick="
        () =>
          this.changeDialogState({
            dialog: 'accountDrawer',
            val: true,
          })
      "
    >
      <p>Sign in or create an account to use your list.</p>
    </EmptyComponent>
  </q-page>
</template>

<script>
import ProductLayoutIcons from 'components/common/ProductLayoutIcons';
import ProductCard from 'components/ProductCard';
import { mapGetters } from 'vuex';

export default {
  name: 'PageFavorites',
  components: {
    ProductCard,
    FilterButtons: () => import('components/common/FilterButtons'),
    // lazy load component
    ProductSkeleton: () => import('components/category/ProductSkeleton'),
    ProductLayoutIcons,
  },
  meta() {
    return {
      title: 'Favorites',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      showSortByDialog: false,
      showCategoryDialog: false,
      parameters: {},
      scrollCounter: 0,
    }
  },
  computed: {
    categoriesOption() {
      return this.categories.map((categorie) => {
        return { value: categorie.categoryID, label: categorie.categoryName }
      })
    },
    objRequest() {
      return {
        sortBy: this.favoriteFilters.sortBy,
        categoryID: this.favoriteFilters.category,
        PageSize: this.parameters.PageSize,
      }
    },
    ...mapGetters('favorites', [
      'sortOptions',
      'categories',
      'favorites',
      'defaultParams',
      'nextPageNumber',
      'totalCount',
      'loading',
      'loadingCategory',
    ]),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    favoriteFilters() {
      return this.getFilterByPage('favorite')
    },
  },
  async created() {
    this.parameters = Object.assign({}, this.parameters, this.defaultParams)
    this.$store.dispatch('favorites/getCategories')
  },
  methods: {
    onChangeCategory(category) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'favorite',
        filter: {
          ...this.favoriteFilters,
          category,
        },
      })
    },
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'favorite',
        filter: {
          ...this.favoriteFilters,
          sortBy,
        },
      })
    },
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'favorite',
        filter: {
          ...this.favoriteFilters,
          isListView,
        },
      })
    },
    fetchNext(index, done) {
      let nextPageNumber = this.nextPageNumber(this.parameters.PageSize)

      let params = {
        Page: nextPageNumber,
        ...this.objRequest,
      }

      this.$store.dispatch('favorites/getFavorites', params).then((hasNext) => {
        done(!hasNext)
        if (!this.isMobile && this.$refs.productLayoutIcons) {
          this.$refs.productLayoutIcons.toggleView(
            this.favoriteFilters.isListView ? 'list' : 'grid'
          )
        }
      })
    },
    refreshListing(value) {
      this.$store.commit('favorites/SET_FAVORITES', {})
      this.$refs.infiniteScroll.reset()
      this.$refs.infiniteScroll.resume()
      this.$refs.infiniteScroll.trigger()
      this.showSortByDialog = false
      this.showCategoryDialog = false
    },
    scrollPosition() {
      this.scrollCounter += 1
      if (
        this.scrollCounter % this.parameters.PageSize == 0 &&
        this.totalCount > 0
      )
        this.$root.$emit('scroll-to-position', Math.floor(Math.random() * 200))
    },
  },
  destroyed() {
    this.$store.commit('favorites/RESET_STATE')
  },
}
</script>
<style lang="scss">
.favouritesPage {
  .wishlist-btn {
    right: 0;
  }
  .page-title {
    padding: 15px 0 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 20px 40px;
    }
    @media (max-width: 1023px) {
      .page-title--left,
      .page-title--right {
        padding: 0 20px;
      }
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 15px;
      line-height: normal;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      color: $primary;
      color: var(--q-color-primary) !important;
      strong {
        font-weight: 600;
      }
      @media (min-width: 1440px) {
        font-size: 18px;
      }
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    .page-title--right {
      display: flex;
      flex-direction: row;
      align-items: center;
      .sorting,
      .showitems {
        margin-right: 15px;
        span {
          font-size: 15px;
          font-weight: normal;
          color: #888888;
          @media (min-width: 1681px) {
            font-size: 18px;
          }
        }
        .q-field {
          margin-left: 10px;
        }
      }
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  /* Content */
  .page-content {
    padding: 15px;
    @media (min-width: 1024px) {
      padding: 20px;
    }
    &.favouriteItems {
      .product-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        .product-items--card {
          width: 50%;
          @media (min-width: 600px) {
            width: 33.33%;
          }
          @media (min-width: 1024px) {
            width: 25%;
          }
          @media (min-width: 1200px) {
            width: 20%;
          }
          @media (min-width: 1440px) {
            width: 16.66%;
          }
          @media (min-width: 1600px) {
            width: 16.66%;
          }
          @media (min-width: 1920px) {
            width: 14.28%;
          }
          &.list-view {
            width: 100%;
            min-height: 200px;
            display: flex;
            .product-offer-container {
              .product-offer-dropdown {
                min-width: 300px;
                top: 10px;
              }
            }
            .product-image {
              display: flex;
              width: 100%;
              min-width: 200px;
              max-width: 200px;

              a.no-underline,
              a.underline {
                width: 100%;
              }
              @media (max-width: 767px) {
                min-width: 150px;
                max-width: 150px;
              }
              @media (max-width: 360px) {
                min-width: 100px;
                max-width: 100px;
              }
            }
            .product-info {
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .product-title {
                font-size: 16px;
                line-height: 1.3;
                font-weight: bold;
              }
              .product-price-favorite {
                min-width: 160px;
                margin-left: auto;
                // text-align: right;
              }
              .produc-title-price {
                display: flex;
                flex-wrap: nowrap;
                min-width: 100%;
              }
              .product-title-container {
                order: -1;
                padding-right: 40px;
              }
              .product-desc {
                color: $grey;
              }
              a {
                .q-card__section {
                  > .row.column {
                    flex-direction: row;
                    > .col {
                      flex: 0 0 auto;
                    }
                  }
                }
              }
              .product-cart,
              .productOption {
                width: 180px;
              }
            }
          }
        }
      }
      .product-count {
        font-size: 14px;
        color: #888888;
        margin-bottom: 15px;
        @media (min-width: 1024px) {
          font-size: 15px;
        }
        @media (min-width: 1681px) {
          font-size: 18px;
        }
      }
    }
    .product-items--card {
      min-height: 100%;
      padding: 10px;
      border: 1px solid #eeeeee;
      &:hover {
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16) !important;
      }
      @media (max-width: 599px) {
        padding: 5px;
        min-height: 320px;
      }
      .wishlist-container {
        right: 0;
      }
    }

    .text-h5 {
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
      strong {
        font-size: 18px;
      }
    }
  }

  .q-breadcrumbs {
    display: inline-block;
  }
  .count-loader {
    float: right;
    right: 35px;
  }
}
.pinCart {
  .favouritesPage {
    .page-content.favouriteItems {
      .product-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        .product-items--card {
          width: 50%;
          @media (min-width: 600px) {
            width: 50%;
          }
          @media (min-width: 1024px) {
            width: 33.33%;
          }
          @media (min-width: 1280px) {
            width: 25%;
          }
          @media (min-width: 1480px) {
            width: 25%;
          }
          @media (min-width: 1600px) {
            width: 20%;
          }
          @media (min-width: 1920px) {
            width: 16.66%;
          }
          &.list-view {
            width: 100%;
          }
        }
      }
    }
  }
}
.q-infinite-scroll__loading {
  width: 100%;
}

// list view skeleton
.page-content.favouriteItems
  .product-items
  .product-items--card.list-view-skeleton {
  width: 100%;
  min-height: 200px;
  display: flex;
  .product-thumb-skeleton {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    @media (max-width: 767px) {
      min-width: 150px;
      max-width: 150px;
    }
    @media (max-width: 360px) {
      min-width: 100px;
      max-width: 100px;
    }
  }
  .product-info-skeleton {
    margin-left: 15px;
    width: 100%;
  }
  .product-skeleton-top {
    max-width: 100px;
  }
  .product-skeleton-fav {
    float: right;
  }
  .product-skeleton-title {
    max-width: 40%;
  }
  .product-skeleton-btn {
    max-width: 200px;
  }
}
</style>
