var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"favouritesPage"},[(_vm.isLoggedIn)?[(_vm.loading && !_vm.isMobile)?[_c('div',{staticClass:"page-title"},[_c('div',{staticClass:"row items-center full-width"},[_c('div',{staticClass:"col-6 col-md-6 q-my-xs"},[_c('q-skeleton',{attrs:{"width":"200px","height":"20px"}}),_c('q-skeleton',{staticClass:"q-mt-sm",attrs:{"type":"text","width":"160px","height":"20px"}})],1),_c('div',{staticClass:"col-12 col-md-2 offset-md-4 col-lg-1 offset-lg-5"},[_c('q-skeleton',{attrs:{"width":"100%","height":"20px"}})],1)])])]:[(!_vm.isMobile)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.totalCount > 0 || _vm.categories.length > 0),expression:"loading || totalCount > 0 || categories.length > 0"}],staticClass:"page-title"},[_c('div',{staticClass:"page-title--left"},[_c('h1',[_vm._v(" Favorites ("+_vm._s(_vm.totalCount)+") ")]),_c('q-breadcrumbs',[_c('q-breadcrumbs-el',{attrs:{"label":"Home","to":{ name: 'Home' }}}),_c('q-breadcrumbs-el',{attrs:{"label":"Favorites"}})],1)],1),_c('div',{staticClass:"page-title--right"},[(_vm.loadingCategory)?_c('q-skeleton',{attrs:{"width":"100px","height":"40px"}}):(_vm.categories && _vm.categories.length > 1)?_c('div',{staticClass:"sorting flex items-center"},[_c('span',[_vm._v("Category:")]),_c('q-select',{attrs:{"dense":"","borderless":"","value":_vm.favoriteFilters.category,"options":_vm.categories,"option-value":"categoryID","option-label":(item) => item ? _vm.$options.filters.titleCase(item.categoryName) : '',"emit-value":"","map-options":"","transition-show":"jump-up","transition-hide":"jump-up"},on:{"input":(val) => {
                  _vm.onChangeCategory(val)
                  _vm.refreshListing(val)
                }}})],1):_vm._e(),(_vm.categories.length > 1 && _vm.sortOptions)?_c('div',{staticClass:"showitems flex items-center"},[_c('span',[_vm._v("Sort:")]),_c('q-select',{attrs:{"dense":"","borderless":"","value":_vm.favoriteFilters.sortBy,"options":_vm.sortOptions,"option-value":"value","option-label":"label","emit-value":"","map-options":"","transition-show":"jump-up","transition-hide":"jump-up"},on:{"input":(val) => {
                  _vm.sortByModified(val)
                  _vm.refreshListing(val)
                }}})],1):_vm._e(),_c('ProductLayoutIcons',{ref:"productLayoutIcons",attrs:{"isListView":!_vm.isMobile ? _vm.favoriteFilters.isListView : false},on:{"changeView":_vm.changeView}})],1)]):(_vm.isMobile)?[_c('FilterButtons',{attrs:{"filterBtnClick":() => {
              this.showCategoryDialog = true
            },"sortBtnClick":() => {
              this.showSortByDialog = true
            },"filterBtnName":"Category"}}),(_vm.categories && _vm.categories.length > 1)?_c('q-dialog',{attrs:{"position":"bottom","maximized":""},model:{value:(_vm.showCategoryDialog),callback:function ($$v) {_vm.showCategoryDialog=$$v},expression:"showCategoryDialog"}},[_c('q-card',{staticClass:"mobileCardDialogue"},[_c('q-item-label',{staticClass:"mobileSortFilter"},[_c('h5',{staticClass:"text-h5"},[_vm._v("Filter by")]),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"close",attrs:{"flat":"","dense":""}},[_c('q-icon',{staticClass:"close cursor-pointer",attrs:{"name":"close"}})],1)],1),_c('q-scroll-area',{staticStyle:{"height":"calc(100vh - 170px)"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[_c('q-option-group',{staticClass:"q-pa-md",attrs:{"options":_vm.categoriesOption,"type":"radio","value":_vm.favoriteFilters.category},on:{"input":(val) => {
                    _vm.onChangeCategory(val)
                    _vm.refreshListing(val)
                  }},scopedSlots:_vm._u([{key:"label",fn:function(category){return [_vm._v("\n                  "+_vm._s(_vm.$options.filters.titleCase(category.label))+"\n                ")]}}],null,false,654947872)})],1)],1)],1):_vm._e(),_c('q-dialog',{attrs:{"position":"bottom","transition-hide":"slide-down","transition-show":"slide-up","maximized":""},model:{value:(_vm.showSortByDialog),callback:function ($$v) {_vm.showSortByDialog=$$v},expression:"showSortByDialog"}},[_c('q-card',{staticClass:"mobileCardDialogue"},[_c('q-item-label',{staticClass:"mobileSortFilter"},[_c('h5',{staticClass:"text-h5"},[_vm._v("Sort by")]),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"close",attrs:{"flat":"","dense":""}},[_c('q-icon',{staticClass:"close cursor-pointer",attrs:{"name":"close"}})],1)],1),_c('q-option-group',{staticClass:"q-pa-md",attrs:{"options":_vm.sortOptions,"type":"radio","value":_vm.favoriteFilters.sortBy},on:{"input":(val) => {
                  _vm.sortByModified(val)
                  _vm.refreshListing(val)
                }}})],1)],1)]:_vm._e()],_c('div',{staticClass:"page-content favouriteItems"},[_c('q-infinite-scroll',{ref:"infiniteScroll",staticClass:"product-items",attrs:{"offset":1500},on:{"load":_vm.fetchNext},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('ProductSkeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"isListView":!_vm.isMobile ? _vm.favoriteFilters.isListView : false}}),_c('LoadMoreSpinner',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]})]},proxy:true}],null,false,1917482747)},_vm._l((_vm.favorites),function(product,index){return _c('ProductCard',{key:product.productID + '-' + index,class:{
            'list-view': !_vm.isMobile ? _vm.favoriteFilters.isListView : false,
          },attrs:{"product":product,"showClose":true,"isListView":!_vm.isMobile ? _vm.favoriteFilters.isListView : false},on:{"removeItemFromFavorites":_vm.scrollPosition}})}),1)],1),_c('EmptyComponent',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading && _vm.totalCount == 0),expression:"!loading && totalCount == 0"}],attrs:{"image":"images/empty-wishlist.png","btnText":"Start Shopping","to":"/"}},[_c('p',[_vm._v("You don't have any Favorites.")])])]:_c('EmptyComponent',{attrs:{"image":"images/empty-wishlist.png","btnText":"Sign In","btnClass":"q-pr-lg q-pl-lg q-mt-sm ","btnClick":() =>
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })}},[_c('p',[_vm._v("Sign in or create an account to use your list.")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }